<template>
  <a-tooltip placement="left" title="添加兄弟/子节点">
    <a-dropdown trigger="click" placement="bottom">
      <a-button type="link" >
        <template #icon>
          <PlusSquareOutlined/>
        </template>
      </a-button>
      <template #overlay>
        <a-menu>
          <a-menu-item>
            <span @click="addFieldAction(false)">兄弟节点</span>
          </a-menu-item>
          <a-menu-item>
            <span @click="addFieldAction(true)">子节点</span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </a-tooltip>
</template>

<script>
import { PlusSquareOutlined, CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons-vue'
export default {
  name: 'index',
  components: { PlusSquareOutlined },
  props: {
    prefix: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const addFieldAction = (isChild) => {
      emit('add-field', isChild)
    }
    return {
      addFieldAction,
    }
  }
}
</script>

<style scoped>

</style>
