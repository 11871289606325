import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b3ed952"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "schema-item" }
const _hoisted_2 = { class: "name-input-row" }
const _hoisted_3 = { class: "option-formStyle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaretDownOutlined = _resolveComponent("CaretDownOutlined")!
  const _component_CaretRightOutlined = _resolveComponent("CaretRightOutlined")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_InputView = _resolveComponent("InputView")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input_group = _resolveComponent("a-input-group")!
  const _component_DefaultValue = _resolveComponent("DefaultValue")!
  const _component_MinusSquareOutlined = _resolveComponent("MinusSquareOutlined")!
  const _component_DropPlus = _resolveComponent("DropPlus")!
  const _component_PlusSquareOutlined = _resolveComponent("PlusSquareOutlined")!
  const _component_schema_array = _resolveComponent("schema-array")!
  const _component_schema_object = _resolveComponent("schema-object")!
  const _component_ValueListDialog = _resolveComponent("ValueListDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, { align: "middle" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          span: $setup.nameSpanNum,
          style: _normalizeStyle(_ctx.tagPaddingLeftStyle),
          class: "col-item name-item col-item-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, {
              align: "middle",
              justify: "space-around"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  span: 2,
                  class: "down-style-col"
                }, {
                  default: _withCtx(() => [
                    (_ctx.itemValue.type === 'object')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.handleClickIcon && $setup.handleClickIcon(...args))),
                          class: "down-style"
                        }, [
                          (_ctx.showIcon)
                            ? (_openBlock(), _createBlock(_component_CaretDownOutlined, { key: 0 }))
                            : (_openBlock(), _createBlock(_component_CaretRightOutlined, { key: 1 }))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 22 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_InputView, {
                        showAll: "",
                        style: {"flex-grow":"1","overflow":"hidden","width":"100px"},
                        value: _ctx.paramsName,
                        disabled: _ctx.itemValue.disabled
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            style: {"width":"100%"},
                            class: _normalizeClass([_ctx.isError?'error':'']),
                            value: _ctx.paramsName,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.paramsName) = $event)),
                            disabled: _ctx.itemValue.disabled,
                            onBlur: $setup.handleChangeName
                          }, null, 8, ["class", "value", "disabled", "onBlur"])
                        ]),
                        _: 1
                      }, 8, ["value", "disabled"]),
                      _createVNode(_component_a_tooltip, {
                        title: "全选",
                        placement: "top",
                        class: "check-all show-all"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_checkbox, {
                            checked: _ctx.isChecked,
                            "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isChecked) = $event)),
                            onChange: $setup.handleEnableRequire
                          }, null, 8, ["checked", "onChange"])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["span", "style"]),
        _createVNode(_component_a_col, {
          span: $setup.spanNum,
          class: "col-item"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputView, {
              select: "",
              value: _ctx.itemValue.type,
              placeholder: "类型",
              disabled: _ctx.itemValue.disabled && !_ctx.itemValue.canChangeType
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  placeholder: "类型",
                  disabled: _ctx.itemValue.disabled && !_ctx.itemValue.canChangeType,
                  value: _ctx.itemValue.type,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.itemValue.type) = $event)),
                  onChange: $setup.handleChangeType
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SCHEMA_TYPE, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: item,
                        label: item,
                        value: item
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["disabled", "value", "onChange"])
              ]),
              _: 1
            }, 8, ["value", "disabled"])
          ]),
          _: 1
        }, 8, ["span"]),
        ($props.showAll)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_a_col, {
                span: $setup.spanNum,
                class: "col-item col-item-format"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputView, {
                    select: "",
                    value: _ctx.itemValue.format,
                    placeholder: "格式",
                    disabled: $setup.formatDisable
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        disabled: $setup.formatDisable,
                        value: _ctx.itemValue.format,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.itemValue.format) = $event)),
                        allowClear: "",
                        placeholder: "格式"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SCHEMA_FORMAT, (item) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: item,
                              label: item,
                              value: item
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["disabled", "value"])
                    ]),
                    _: 1
                  }, 8, ["value", "disabled"])
                ]),
                _: 1
              }, 8, ["span"]),
              _createVNode(_component_a_col, {
                span: $setup.spanNum,
                class: "col-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputView, {
                    value: _ctx.itemValue.minLength,
                    disabled: $setup.lengthDisable,
                    placeholder: "最小长度"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        controls: false,
                        disabled: $setup.lengthDisable,
                        onBlur: _cache[5] || (_cache[5] = ($event: any) => ($setup.checkMin(true))),
                        placeholder: "最小长度",
                        value: _ctx.itemValue.minLength,
                        "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.itemValue.minLength) = $event))
                      }, null, 8, ["disabled", "value"])
                    ]),
                    _: 1
                  }, 8, ["value", "disabled"])
                ]),
                _: 1
              }, 8, ["span"]),
              _createVNode(_component_a_col, {
                span: $setup.spanNum,
                class: "col-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputView, {
                    value: _ctx.itemValue.maxLength,
                    disabled: $setup.lengthDisable,
                    placeholder: "最大长度"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        controls: false,
                        disabled: $setup.lengthDisable,
                        min: _ctx.itemValue.minLength,
                        placeholder: "最大长度",
                        value: _ctx.itemValue.maxLength,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.itemValue.maxLength) = $event))
                      }, null, 8, ["disabled", "min", "value"])
                    ]),
                    _: 1
                  }, 8, ["value", "disabled"])
                ]),
                _: 1
              }, 8, ["span"]),
              _createVNode(_component_a_col, { span: $setup.spanNum }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputView, {
                    value: _ctx.itemValue.min,
                    disabled: $setup.numberDisable,
                    placeholder: "最小值"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        controls: false,
                        disabled: $setup.numberDisable,
                        min: -9007199254740992,
                        onBlur: _cache[8] || (_cache[8] = ($event: any) => ($setup.checkMin(false))),
                        placeholder: "最小值",
                        value: _ctx.itemValue.min,
                        "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.itemValue.min) = $event))
                      }, null, 8, ["disabled", "value"])
                    ]),
                    _: 1
                  }, 8, ["value", "disabled"])
                ]),
                _: 1
              }, 8, ["span"]),
              _createVNode(_component_a_col, { span: $setup.spanNum }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputView, {
                    value: _ctx.itemValue.max,
                    disabled: $setup.numberDisable,
                    placeholder: "最大值"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        controls: false,
                        disabled: $setup.numberDisable,
                        max: 9007199254740992,
                        min: _ctx.itemValue.min,
                        placeholder: "最大值",
                        value: _ctx.itemValue.max,
                        "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.itemValue.max) = $event))
                      }, null, 8, ["disabled", "min", "value"])
                    ]),
                    _: 1
                  }, 8, ["value", "disabled"])
                ]),
                _: 1
              }, 8, ["span"]),
              _createVNode(_component_a_col, {
                span: $setup.spanNum,
                class: "col-item value-list"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_group, { compact: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        disabled: $setup.valueListDisable,
                        value: _ctx.itemValue.valueList,
                        "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.itemValue.valueList) = $event)),
                        placeholder: "值列表"
                      }, null, 8, ["disabled", "value"]),
                      _createVNode(_component_a_button, {
                        onClick: $setup.showValueList,
                        disabled: $setup.valueListDisable
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        _: 1
                      }, 8, ["onClick", "disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["span"])
            ], 64))
          : _createCommentVNode("", true),
        _createVNode(_component_a_col, {
          span: $setup.spanNum,
          class: "col-item"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputView, {
              value: _ctx.itemValue.title,
              placeholder: "标题"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: "标题",
                  value: _ctx.itemValue.title,
                  "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.itemValue.title) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }, 8, ["span"]),
        ($props.showAll)
          ? (_openBlock(), _createBlock(_component_a_col, {
              key: 1,
              span: $setup.spanNum,
              class: "col-item"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputView, {
                  disabled: $setup.defaultValueDisable,
                  value: _ctx.itemValue.defaultValue,
                  placeholder: "默认值"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DefaultValue, {
                      ref: "defaultValueRef",
                      disabled: $setup.defaultValueDisable,
                      inputType: _ctx.itemValue.type,
                      value: _ctx.itemValue.defaultValue,
                      "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.itemValue.defaultValue) = $event)),
                      placeholder: "默认值"
                    }, null, 8, ["disabled", "inputType", "value"])
                  ]),
                  _: 1
                }, 8, ["disabled", "value"])
              ]),
              _: 1
            }, 8, ["span"]))
          : _createCommentVNode("", true),
        _createVNode(_component_a_col, {
          span: $setup.actionSpanNum,
          class: "col-item col-item-setting"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              danger: "",
              type: "link",
              onClick: $setup.handleDelete,
              class: _normalizeClass([_ctx.itemValue.disabled?'hidden':'','close'])
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_MinusSquareOutlined)
              ]),
              _: 1
            }, 8, ["onClick", "class"]),
            (_ctx.itemValue.type === 'object')
              ? (_openBlock(), _createBlock(_component_DropPlus, {
                  key: 0,
                  name: $props.name,
                  prefix: $props.prefix,
                  onAddField: $setup.handleAdd
                }, null, 8, ["name", "prefix", "onAddField"]))
              : (_openBlock(), _createBlock(_component_a_tooltip, {
                  key: 1,
                  title: "添加兄弟节点",
                  placement: "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "link",
                      onClick: _cache[14] || (_cache[14] = ($event: any) => ($setup.handleAdd(false)))
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_PlusSquareOutlined)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }, 8, ["span"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.itemValue.type === 'array')
        ? (_openBlock(), _createBlock(_component_schema_array, {
            key: 0,
            showAll: $props.showAll,
            data: _ctx.itemValue,
            "editor-id": $props.editorId,
            prefix: $setup.prefixArray
          }, null, 8, ["showAll", "data", "editor-id", "prefix"]))
        : _createCommentVNode("", true),
      (_ctx.itemValue.type === 'object')
        ? _withDirectives((_openBlock(), _createBlock(_component_schema_object, {
            key: 1,
            ref: "schemaObjRef",
            showAll: $props.showAll,
            data: _ctx.itemValue,
            "editor-id": $props.editorId,
            prefix: $setup.nameArray
          }, null, 8, ["showAll", "data", "editor-id", "prefix"])), [
            [_vShow, _ctx.showIcon]
          ])
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ValueListDialog, {
      visible: _ctx.showValueListDialog,
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.showValueListDialog) = $event)),
      value: _ctx.itemValue.valueList,
      "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.itemValue.valueList) = $event))
    }, null, 8, ["visible", "value"])
  ]))
}