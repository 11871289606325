
import { computed, reactive, toRefs, ref, watch, PropType } from 'vue'
import { defaultSchema, SCHEMA_FORMAT, SCHEMA_TYPE, } from '../../utils/const'
import DefaultValue from './components/DefaultValue/index.vue'
import DropPlus from './components/DropPlus/index.vue'
import SchemaArray from './SchemaArray.vue'
import SchemaObject from './SchemaObject.vue'
import ValueListDialog from '../ValueListDialog/index.vue'
import InputView from './components/InputView/index.vue'
import { uuid } from '../../utils/tools'
import {
  CaretDownOutlined,
  CaretRightOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  EditOutlined
} from '@ant-design/icons-vue'
import { cloneDeep, debounce, omit, set } from 'lodash'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { userMapState } from '@/store/userMapper'
import { IJsonSchema, ISchemaItem } from '#/JsonSchema/type'
import { IStoreState } from '@/store/modules/jsonSchema'

interface IState {
  isError: boolean
  showIcon: boolean
  isChecked: boolean
  showValueListDialog: boolean
  SCHEMA_FORMAT: string[]
  SCHEMA_TYPE: string[]
  tagPaddingLeftStyle: Record<string, string>
  paramsName: string
  itemValue: ISchemaItem
}

export default {
  name: 'SchemaItem',
  components: {
    DefaultValue,
    InputView,
    DropPlus,
    'schema-array': SchemaArray,
    'schema-object': SchemaObject,
    ValueListDialog,
    CaretDownOutlined, CaretRightOutlined, PlusSquareOutlined, MinusSquareOutlined, EditOutlined,
  },
  props: {
    showAll: { type: Boolean, default: true },
    editorId: {
      type: String,
      default: 'editor_id'
    },
    name: {
      type: String,
      default: ''
    },
    prefix: {
      type: Array as PropType<String[]>,
      default: () => []
    },
    data: {
      type: Object as PropType<IJsonSchema>,
      default: () => {
      }
    }
  },
  emits: ['lock', 'unlock', 'updateName', 'delete'],
  setup(props, { emit }) {
    const store = useStore()
    const defaultValueRef = ref<null | any>(null)
    const schemaObjRef = ref<null | any>(null)
    const state = reactive<IState>({
      isError: false,
      SCHEMA_FORMAT,
      SCHEMA_TYPE,
      showIcon: false,
      tagPaddingLeftStyle: {},
      itemValue: {},
      paramsName: props.name,
      isChecked: props.data.required?.includes(props.name) || false,
      showValueListDialog: false,
    })
    const { allRequiredMap }: { allRequiredMap: Record<string, boolean> } = userMapState('jsonSchema', ['allRequiredMap']) as IStoreState
    const isAllRequired = computed<boolean>(() => allRequiredMap.value ? allRequiredMap.value[props.editorId] : false)
    const nameSpanNum = computed<number>(() => props.showAll ? 4 : 8)
    const actionSpanNum = computed<number>(() => props.showAll ? 2 : 4)
    const spanNum = computed<number>(() => props.showAll ? 2 : 4)
    const paramKeys = computed(() => props.data.properties ? Object.keys(props.data.properties) : [])
    const prefixArray = computed<string[]>(() => [...props.prefix, props.name])
    const nameArray = computed<string[]>(() => [...prefixArray.value, 'properties'])
    const formatDisable = computed<boolean>(() => !['string', 'number', 'integer'].includes(state.itemValue.type))
    const lengthDisable = computed<boolean>(() => state.itemValue.type !== 'string')
    const numberDisable = computed<boolean>(() => !['number', 'integer'].includes(state.itemValue.type))
    const valueListDisable = computed<boolean>(() => !['string', 'number', 'integer'].includes(state.itemValue.type))
    const defaultValueDisable = computed<boolean>(() => ['object', 'array'].includes(state.itemValue.type))

    watch(() => isAllRequired.value, (value: boolean) => {
      state.isChecked = value
      handleEnableRequire()
    })

    const init = () => {
      state.itemValue = props.data.properties[props.name]
      const length = props.prefix.filter(name => name !== 'properties').length
      state.tagPaddingLeftStyle = {
        paddingLeft: `${10 * (length + 1)}px`
      }
    }
    init()

    //改变名称
    const handleChangeName = (e: any) => {
      let { value } = e.target
      if (value.trim() === props.name) return
      const reg = /^[a-zA-Z][a-zA-Z0-9_]*$/g
      // state.isError = !reg.test(value)
      if (!reg.test(value)) {
        state.paramsName = props.name
        return message.warning('参数名称由字符、数字、下划线组成，且必须以字母开头！')
      }
      if (paramKeys.value.includes(value)) {
        state.paramsName = props.name
        return message.warning('参数名称已存在！')
      }
      emit('lock')
      const oldName = props.name
      const copy = cloneDeep(state.itemValue)
      try {
        // props.data.properties = cloneDeep(omit(props.data.properties,[props.name]))
        delete props.data.properties[props.name]
        props.data.properties[value] = copy
      } catch (e) {
        emit('unlock')
      }
      emit('updateName', { oldName, newName: value })
      const _index = props.data.required?.findIndex(item => item === props.name)
      if (_index >= 0) {
        props.data.required.splice(_index, 1, value)
      }
    }

    //  是否必须
    const handleEnableRequire = () => {
      if (!props.data.required) props.data.required = []
      const _index = props.data.required?.findIndex(item => item === props.name)
      if (state.isChecked && _index < 0) {
        props.data.required.push(props.name)
      }
      if (!state.isChecked && _index >= 0) {
        props.data.required.splice(_index, 1)
      }
    }
    //  切换参数类型
    const handleChangeType = (value: string) => {
      props.data.properties[props.name] = cloneDeep(defaultSchema[value])
      state.itemValue = props.data.properties[props.name]
      defaultValueRef.value?.clearInputValue()
    }

    const handleAdd = (isChild: boolean) => {
      const newName = 'field_' + uuid()
      if (isChild) {
        state.itemValue.properties[newName] = cloneDeep(defaultSchema.string)
        schemaObjRef.value?.handleAdd({ newName })
      } else {
        props.data.properties[newName] = cloneDeep(defaultSchema.string)
        emit('add', { prevName: state.paramsName, newName })
      }
      store.commit('jsonSchema/CALL_SCROLL_FUNCTION', props.editorId)
    }

    const handleDelete = () => {
      // omit(props.data, ['properties', props.name])
      delete props.data.properties[props.name]
      emit('delete', props.name)
      if (props.data.required?.length) {
        const _index = props.data.required?.findIndex(item => item === props.name)
        props.data.required.splice(_index, 1)
      }
    }

    //校验大小值
    const checkMin = (isLength: boolean) => {
      const { maxLength, minLength, max, min } = state.itemValue
      if (isLength) {
        if (!maxLength || maxLength < minLength) {
          state.itemValue.maxLength = minLength
        }
      } else {
        if (!max || max < min) {
          state.itemValue.max = min
        }
      }
    }

    //显示 值列表
    const showValueList = () => {
      if (valueListDisable.value) return
      state.showValueListDialog = true
    }
    const handleClickIcon = () => {
      state.showIcon = !state.showIcon
    }

    return {
      defaultValueRef,
      schemaObjRef,
      ...toRefs(state),
      nameSpanNum,
      actionSpanNum,
      spanNum,
      handleClickIcon,
      handleChangeName,
      handleEnableRequire,
      handleChangeType,
      checkMin,
      showValueList,
      handleDelete,
      handleAdd,
      nameArray,
      prefixArray,
      formatDisable,
      lengthDisable,
      numberDisable,
      valueListDisable,
      defaultValueDisable,
    }
  }
}
